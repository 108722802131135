.BackgroundCanvas {
    position: fixed;
    pointer-events: all;
    /* we want this to appear above tiles and Page */
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.BackgroundCanvas.background {
    opacity: 0.3;
    pointer-events: none;
    /* we want this to still appear above tiles but below Page */
    z-index: 1;

}