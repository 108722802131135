.Brag ul {
    color: LightGrey;
    list-style: square;
    list-style: disclosure-open;
}

.Brag li, .Brag__date, .Brag__name {
    background: var(--bgBlack);
    opacity: 0.9;
}

.Brag__sublist li {
    color: grey;
}

.Brag li {
    margin-bottom: 0.5rem;
}

.Brag__date {
    margin-right: 0.5rem;
}

.Brag__name {
    margin-right: 0.5rem;
}

.Brag__sublist {
    margin-left: 1rem;
}

.Brag__sublist li {
    font-size: 80%;
    margin-bottom: 0;
}