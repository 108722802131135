.Page {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    font-size: 140%;
    padding: 0 20%;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 30%, rgba(0,0,0,0.65) 70%, rgba(0,0,0,0) 100%);
}

.Page a {
    text-decoration: underline;
}

.Page__scroll_to_top {
    border: none;
    outline: none;
    color: var(--fgWhite1);
    font-size: 250%;
    background: none;
    position: fixed;
    bottom: -0.8rem;
    right: -0.2rem;
    padding: 0.5rem;
    cursor: pointer;
}

.Page__page_top {
    display: none;
}