.Articles ul li {
    color: LightGrey;
    list-style: disclosure-open;
}

.Articles li, .Articles__date, .Articles__name {
    background: var(--bgBlack);
}

.Articles li {
    margin-bottom: 1.5rem;
}

.Articles__date {
    margin-right: 0.5rem;
}

.Articles__name {
    margin-right: 0.5rem;
}

.Articles__info {
    font-size: 80%;
}