
@media (min-width: 900px) and (max-width: 1200px) {
    body, html, .App, #root {
        font-size: 16px;
    }
}

@media (min-width: 0px) and (max-width: 900px) {
    body, html, .App, #root {
        font-size: 14px;
    }
    .Page {
        padding: 0 2rem;
    }
}

@media (min-width: 1500px) {
    .TileContainer {
        display: grid;
        grid-template-columns: 2.5fr 1fr 1fr 1fr;
    }

    .TileContainer__column {
        font-size: 90%;
    }

    .TileContainer__hero .Tile__body {
        /* To align with other columns that have smaller font-size */
        position: relative;
        bottom: 0.2rem;
    }

    .Tile.TileArticles .Tile__header {
        position: relative;
        bottom: 0.1rem;
    }
}

@media (min-width: 800px) and (max-width: 1500px) {
    .TileContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* Switch to single column layout */
@media (min-width: 0px) and (max-width: 800px) {
    .TileContainer {
        display: block;
    }

    .Tile__body {
        border: none;
    }

    .Tile {
        padding: 2rem;
    }
}