* {
    box-sizing: border-box;
    --bgBlack: #181818;
    --fgWhite1: white;
    --fgWhite2: #ccc;
    --fgWhite3: #aaa;
}

body, html, .App, #root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    /* fallback */
    background-color: black;
    background-color: var(--bgBlack);
    color: var(--fgWhite1);
    font-family: 'Cutive Mono', monospace;
    font-size: 18px;
}

a, a:visited {
    color: var(--fgWhite1);
    text-decoration: none;
}

button {
    font-family: 'Cutive Mono', monospace;
}

ul {
    padding-left: 0;
}

h1, h2, h3 {
    text-transform: uppercase;
}