.PageTitle {
    position: relative;
}

a.PageTitle__navigate_index {
    position: absolute;
    left: -2.5rem;
    text-decoration: none;
    border: none;
    background: none;
    color: var(--fgWhite1);
    font-size: 2.5rem;
    font-weight: bold;
    cursor: pointer;
}
