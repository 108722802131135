.Tile {
    font-weight: bold;
    padding-top: 2rem;
    padding-left: 0.1rem;
    display: flex;
    flex-direction: column;
    color: var(--fgWhite1);
}

.Tile a, .Tile a:visited {
    color: var(--fgWhite1);
}

.Tile__header {
    text-transform: uppercase;
    font-size: 250%;
    position: relative; /*To allow positioning of external link*/
    padding-right: 1rem; /*To leave space for the external link symbol*/
}

.Tile__body {
    font-size: 140%;
    border-bottom: 3px solid;
    text-transform: uppercase;
    color: var(--fgWhite2);
}

.Tile__body a, .Tile__body a:visited {
    color: var(--fgWhite2);
}

.Tile__body a:hover::after {
    content: " <-";
}

.Tile__body {
    position: relative;
}

.Tile a:hover .Tile__body::after {
    position: absolute;
    bottom: 0;
    right: 0;
    content: " <-";
}

.Tile__date, .Tile__activityType {
    display: inline-block;
    font-size: 120%;
}

.Tile__external-link {
    height: 0.8rem;
    position: absolute;
    right: 0;
    top: 0.1rem;
    opacity: 0.7;
}