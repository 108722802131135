.TileArticles__entry {
}

.TileArticles__entry a, .TileArticles__entry a:visited {
    color: var(--fgWhite1);
}

.TileArticles__entry:last-child {
    margin-bottom: 0;
}

.TileArticles__abstract {
    color: var(--fgWhite3);
    margin-left: 1rem;
}